import React from "react"
import { graphql } from "gatsby"
import { ROUTES } from "consts"
import useStory from "hooks/useStory"
import Page from "components/_common/Page"
import BlogPosts from "components/_pages/blog/BlogPosts"
import renderBloks from "helpers/renderBloks"

export default function BlogTemplate({ pageContext, data }) {
  const {
    layout: layoutStory,
    story: pageStory,
    language: storyblokLanguage,
    currentPage,
    pagesCount,
  } = pageContext

  const [story, layout] = useStory(pageStory, layoutStory)

  const { components_visible_during_pagination, pre_body, post_body } = story

  const slug =
    currentPage === 1
      ? `/${ROUTES.BLOG_PRESLASHLESS}/`
      : `/${ROUTES.BLOG_PRESLASHLESS}/${currentPage}/`

  const allowRenderBloks =
    currentPage === 1 || components_visible_during_pagination
  const preBodyComponents = allowRenderBloks && renderBloks(pre_body)
  const postBodyComponents = allowRenderBloks && renderBloks(post_body)

  return (
    <Page
      {...{ story, layout, slug }}
      layoutProps={{
        isPaginating: currentPage >= 2,
      }}
      langLinks={pageContext.langLinks}
    >
      {preBodyComponents}
      <BlogPosts
        posts={data.blogPosts.edges}
        {...{ storyblokLanguage, pagesCount, currentPage }}
      />
      {postBodyComponents}
    </Page>
  )
}

export const pageQuery = graphql`
  query BLOG_TEMPLATE_QUERY($skip: Int!, $limit: Int!, $language: String!) {
    blogPosts: allStoryblokEntry(
      filter: { field_component: { eq: "blog_post" }, lang: { eq: $language } }
      sort: { fields: field_date_published_string, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          name
          created_at
          lang
          uuid
          slug
          field_component
          full_slug
          content
          is_startpage
          parent_id
          group_id
        }
      }
    }
  }
`
