import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { formatDateIntl, isValidArray } from "helpers"
import Link from "components/_common/Link"
import Img from "components/_common/Image"
import useStory from "hooks/useStory"
import BlogIntroduction from "components/_common/BlogIntroduction"
import { ROUTES } from "consts"
import s from "./blogPostLink.module.scss"

export default function BlogPostLink({ blogPostStory, languagePrefix }) {
  const intl = useIntl()
  const story = useStory(blogPostStory)

  const {
    featured_image,
    categories,
    introduction,
    title,
    date_published,
  } = story
  const to = `/${languagePrefix}${ROUTES.BLOG_PRESLASHLESS}/${blogPostStory.slug}/`
  const mainCategory = isValidArray(categories) && categories[0].name

  const imageComponent = featured_image ? (
    <div className={s.imageWrapper}>
      <Img src={featured_image} fluid />
    </div>
  ) : (
    <div className={s.imagePlaceholder} />
  )

  return (
    <article className={s.blogPostLink}>
      <Link to={to}>
        {imageComponent}
        <div className={s.content}>
          <h4 className={s.title}>{title}</h4>
          <span className={s.category}>{mainCategory}</span>
          <time className={s.date}>{formatDateIntl(date_published, intl)}</time>
          <div className={s.introduction}>
            <BlogIntroduction introduction={introduction} />
          </div>
        </div>
      </Link>
    </article>
  )
}
