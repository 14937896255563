import React from "react"
import cns from "classnames"
import Button from "@material-ui/core/Button"
import { ROUTES, LANGUAGES } from "consts"
import { isStoryblokDefault, isStoryblokChinese } from "helpers/locales"

import { PAGINATION_INDICATORS, PAGINATION_ITEM_TYPES } from "./consts"

import s from "./blogPosts.module.scss"

function getEllipsisPage(i, paginationItems) {
  const prevPage = paginationItems[i - 1].page
  const nextPage = paginationItems[i + 1].page
  return prevPage + Math.ceil((nextPage - prevPage) / 2)
}

export function getPaginationButton(
  paginationItem,
  paginationItemIndex,
  paginationItems,
  languagePrefix
) {
  const { page, type, selected, disabled } = paginationItem
  const href =
    page === 1
      ? `/${languagePrefix}${ROUTES.BLOG_PRESLASHLESS}/`
      : `/${languagePrefix}${ROUTES.BLOG_PRESLASHLESS}/${page}/`
  const key = `pagination-item-${paginationItemIndex}`

  switch (type) {
    case PAGINATION_ITEM_TYPES.PAGE:
      return (
        <Button
          key={key}
          href={href}
          className={cns(selected && s.paginationSelected)}
        >
          {page}
        </Button>
      )
    case PAGINATION_ITEM_TYPES.PREVIOUS:
    case PAGINATION_ITEM_TYPES.NEXT:
      return (
        <Button key={key} href={href} disabled={disabled}>
          {PAGINATION_INDICATORS[type]}
        </Button>
      )
    case PAGINATION_ITEM_TYPES.START_ELLIPSIS:
    case PAGINATION_ITEM_TYPES.END_ELLIPSIS:
      return (
        <Button
          key={key}
          href={`/${languagePrefix}${
            ROUTES.BLOG_PRESLASHLESS
          }/${getEllipsisPage(paginationItemIndex, paginationItems)}/`}
          disabled={disabled}
        >
          ...
        </Button>
      )
    default:
      return null
  }
}

export function getLanguagePrefixFromStoryblokLanguage(storyblokLanguage) {
  if (isStoryblokDefault(storyblokLanguage)) {
    return ""
  }

  if (isStoryblokChinese(storyblokLanguage)) {
    return `${LANGUAGES.CHINESE}/`
  }

  return `${storyblokLanguage}/`
}
