export const PAGINATION_ITEM_TYPES = {
  PREVIOUS: "previous",
  NEXT: "next",
  PAGE: "page",
  START_ELLIPSIS: "start-ellipsis",
  END_ELLIPSIS: "end-ellipsis",
}

export const PAGINATION_INDICATORS = {
  [PAGINATION_ITEM_TYPES.PREVIOUS]: "‹",
  [PAGINATION_ITEM_TYPES.NEXT]: "›",
}
