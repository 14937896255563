import React from "react"
import { ButtonGroup, useMediaQuery } from "@material-ui/core"
import { usePagination } from "@material-ui/lab"
import Wrapper from "components/_common/Wrapper"
import { SCREENS } from "consts"
import {
  getPaginationButton,
  getLanguagePrefixFromStoryblokLanguage,
} from "./helpers"
import BlogPostLink from "./BlogPostLink"
import s from "./blogPosts.module.scss"

export default function BlogPosts({
  posts,
  storyblokLanguage,
  pagesCount,
  currentPage,
}) {
  const languagePrefix = getLanguagePrefixFromStoryblokLanguage(
    storyblokLanguage
  )

  const isPhoneScreen = useMediaQuery(`(max-width: ${SCREENS.PHONE})`)

  const { items: pagination } = usePagination({
    count: pagesCount,
    page: currentPage,
    hideNextButton: isPhoneScreen,
    hidePrevButton: isPhoneScreen,
  })

  return (
    <Wrapper withoutDefaultPaddings>
      <div className={s.articlesList}>
        {posts.map(({ node }) => (
          <BlogPostLink
            key={node.uuid}
            blogPostStory={node}
            languagePrefix={languagePrefix}
          />
        ))}
      </div>
      <div className={s.pagination}>
        <ButtonGroup size="small" aria-label="small outlined button group">
          {pagination.map(
            (paginationItem, paginationItemIndex, paginationItems) =>
              getPaginationButton(
                paginationItem,
                paginationItemIndex,
                paginationItems,
                languagePrefix
              )
          )}
        </ButtonGroup>
      </div>
    </Wrapper>
  )
}
